<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c18 w-4/5"
            >
              Transfert de fonds | <span class="text-90">Recette</span>
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>


          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-c14 mt-4 text-left bloc1 p-3 flex items-center">
              <icon
                  :data="icons.alerte"
                  height="50"
                  width="50"
                  class="cursor-pointer"
                  original
                  color="#FEA511"
                  @click="fermer"
              />
              <div class="ml-6">
                <div class="">
                  <span class="text-yell">Agence expéditrice </span>
                  <span class="">
                    : {{ agence.agency.label.toUpperCase() }}
                  </span>
                </div>

                <div class="mt-2">
                  <span class="text-yell">Commercial</span>
                  <span class="">
                   :
                    <span v-if="user !== null">
                      {{user.fullname}}
                    </span>
                  </span>
                </div>
              </div>
            </div>


            <div class="text-c14 mt-4 text-left flex pb-2 items-center">
              <div class="text-90 w-1/4">
                Espèce
              </div>
              <div class="w-full bord"/>
              <div class="text-right w-1/3 font-c6">
                {{ totalBillet.toLocaleString() }} fcfa
              </div>
            </div>

            <div class="text-c14 text-left flex pb-2 items-center">
              <div class="text-90 w-1/4">
                Chèques
              </div>
              <div class="w-full bord "/>
              <div class="text-right w-1/3 font-c6">
                {{ totalCheque.toLocaleString() }} fcfa
              </div>
            </div>

            <div class="mt-6">
              <multiselect :option="optionDestinataire" v-if="optionDestinataire.length > 0" @info="retourDestinataire" value-t="Destinataire"/>
              <div class="mt-6 inputo text-c13 flex items-center pl-4">
                {{ caisse }}
              </div>
              <textare height="132.53px" radius="5px" placeholder="Ecrivez une note..." class="mt-6" @info="retourNote" />
            </div>

            <div
                v-if="error !== null"
                class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>

            <div class="flex mt-10">
              <div class="w-full flex">
                <buton
                    class="w-1/2"
                    label="Annuler"
                    radius="5px"
                    height="54px"
                    background="#DDDDDD"
                    color="black"
                    :charge="charge"
                    @info="fermer"
                />
                <buton
                    class="w-1/2 ml-6"
                    label="Enregistrer"
                    radius="5px"
                    height="54px"
                    :charge="charge"
                    @info="continuer"
                />
              </div>
            </div>
          </div>


        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import alerte from '../../assets/icons/error.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import multiselect from '../helper/form/multiselect'
import textare from '../helper/form/textarea'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    multiselect,
    textare,
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    cheque: {
      type: Array,
      default: null
    },
    billet: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        fermer,
        arrow,
        transfere,
        alerte
      },

      error: null,
      charge: false,
      agence: null,
      user: null,
      totalBillet: 0,
      totalCheque: 0,
      userData: [],
      optionDestinataire: [],
      destinataire: null,
      caisse: null,
      note: '',
      pdf: [],
      isMultiple: false
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.agence = this.$store.getters.trackTemporal

    if (this.$store.getters.token !== null){
      this.user = this.parseJwt(this.$store.getters.token)
    }

    if (this.billet !== null){
      for (let item in this.billet) {
        this.totalBillet = this.totalBillet + (this.billet[item].count * this.billet[item].amount)
      }
    }

    if (this.cheque !== null){
      for (let item in this.cheque) {
        this.totalCheque = this.totalCheque + this.cheque[item].amount
      }
    }
    this.getUserWallet()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    async continuer() {
      if (this.totalBillet > 0 || this.totalCheque > 0) {
        if (this.destinataire !== null) {
          if (this.note !== '' && this.note !== null) {
            const body = {
              sender: this.agence.id,
              receiver: null,
              type: "CASH",
              note: this.note,
              meta: {},
              data: this.billet
            }

            const bodyCheck = {
              sender: this.agence.id,
              receiver: null,
              type: "CASH",
              note: this.note,
              meta: {},
              data: this.billet
            }

            for (let item in this.userData) {
              if (this.userData[item].person.fullname === this.destinataire) {
                body.receiver = this.userData[item].id
                bodyCheck.receiver = this.userData[item].id
              }
            }

            // On active le payement multiple
            if (this.totalBillet > 0 && this.totalCheque > 0) {
              this.isMultiple = true
            }

            if (this.totalBillet > 0) {
              console.log(body)
              await this.save(body)
            }

        //Enregistrement des cheques
            if (this.totalCheque > 0) {
              let tab = []
              for (let item in this.cheque) {
                tab.push(this.cheque[item].id)
              }

              bodyCheck.data = tab
              bodyCheck.type = "BANK_CHECK"
              console.log(bodyCheck)
              await this.save(bodyCheck)
            }

          } else {
            this.error = 'Veuillez enregistrer une note pour continuer'
          }
        } else {
          this.error = 'Veuillez sélectionner un destinataire pour continuer'
        }
      } else {
        this.error = 'Impossible de transférer moins de 1 FCFA'
      }
    },

    async save(body) {
      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.transfereWallet, body)
          .then(response => {
            this.charge = false
            console.log(response)
            this.pdf.push(response.pdf)
            if (this.isMultiple) {
              if (this.pdf.length === 2) {
                this.$emit('info', this.pdf)
              }
            } else {
              this.$emit('oga', response.pdf)
            }
          })
          .catch(error => {
            this.charge = false
            if (error.data.message === 'Sorry you dont have suffisant amount for this transfer') {
              this.error = 'Solde insuffisant'
            }

            console.log(error)
          })
    },

    retourDestinataire (answer) {
      if (answer !== 'Destinataire') {
        this.destinataire = answer
        for (let item in this.userData){
          if (this.userData[item].person.fullname === answer){
            if (this.userData[item].person.user.type === "ACCOUNTING") {
              this.caisse = 'Caisse recette comptabilité'
            } else {
              this.caisse = 'Caisse ' + answer
            }
          }
        }

      }
    },

    retourNote (answer) {
      this.note = answer
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    getUserWallet () {
      http.post(apiroutes.baseURL + apiroutes.userWallet, {
        "types": [
          "DIRECTION", "ACCOUNTING"
        ]
      })
          .then(response => {
            this.charge = false
            console.log(response)
            this.userData = response
            let tab = []
            for (let item in response) {
              tab.push(response[item].person.fullname)
            }
            this.optionDestinataire = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .moyens{
      background-color: #F5F5F5;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .inputo{
      height: 40px;
      border: 1px solid #DCDCE4;
      border-radius: 4px;
    }
    .bloc{
      border-bottom: 1px solid #DDDDDD;
    }
    .bord{
      border-bottom: 1px dashed #000000
    }

    .bloc1{
      min-height: 40px;
      background: #FFF6EF;
      border-radius: 5px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
